import { For, Show } from 'solid-js'
import { navigate } from 'vike/client/router'

import { LogoutButton } from '#/auth/ui/LogoutButton'
import {
  useSubscriptionActions,
  useSubscriptionState,
} from '#/subscription/state/SubscriptionContext'
import type { QueryOutput } from '#/trpc/useTrpcQuery'
import { useTrpcQuery } from '#/trpc/useTrpcQuery'
import { config, env } from '#/utils/env'

import { Flex } from '../../components/ui/Flex'

const { stackName } = config({
  stackName: env('STACK_NAME'),
})

export default function Page() {
  const [songs] = useTrpcQuery({
    query: 'getSongs',
  })

  const subscription = useSubscriptionState()
  const { requireSubscription } = useSubscriptionActions()

  const handleClickSong = async (song: QueryOutput<'getSongs'>[number]) => {
    await requireSubscription()
    navigate(`/songs/${song._id}`)
  }

  return (
    <Flex
      direction="column"
      style={{ padding: `16px` }}
    >
      <LogoutButton />
      <h1>SazTunes ({stackName})</h1>

      <h2>Songs:</h2>
      <Show when={!subscription.isLoading && songs()}>
        {(getSongs) => (
          <ul>
            <For each={getSongs()}>
              {(song) => (
                <li>
                  <button onClick={() => handleClickSong(song)}>
                    {song.songName}
                  </button>
                </li>
              )}
            </For>
          </ul>
        )}
      </Show>
    </Flex>
  )
}
